<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>
      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from '@/components/account-setting/AccountSettingGeneral.vue'
import AccountSettingPassword from '@/components/account-setting/AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      options: {
        general: {
          email: 'ali@gmail.com',
          fullName: 'ali raza',
          username: 'username',
        },
      },
    }
  },
}
</script>
